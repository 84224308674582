@use "../../styles/mixins" as *;

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  padding: 0 1rem;
  background-color: #fff;
  position: relative;
  z-index: 1;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;

  @include tablet {
    height: 4rem;
    // padding: 0 2%;
    box-shadow: none;
    background-color: #fff;
  }

  @include desktop {
    padding: 0 3%;
    width: 100%;
    height: 5rem;
  }
}

.hamburger {
  background-image: url("../../assets/icons/hamburger.png");
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  cursor: pointer;

  @include tablet {
    display: none;
  }
}

.logo-link {
  text-decoration: none;
  font-size: 1.4rem;
  color: #436850;
  font-weight: 1000;
  cursor: pointer;

  @include tablet {
    width: 40%;
  }

  @include desktop {
    font-size: 2rem;
    font-weight: 600;
    width: 50%;
  }
}

.link {
  text-decoration: none;
  color: #436850;
  font-weight: 400;

  @include tablet {
  }

  @include desktop {

  }
}

.link:hover {
  font-weight: 700;
}

.list {
  padding: 1rem 1.4rem;
  line-height: 2;
  position: absolute;
  width: 100%;
  background-color: #c6dcba;
  margin: 17rem 0 0 -1rem;
  display: none;
  flex-direction: column;

  @include tablet {
    background-color: #fff;
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    margin: 5rem 0;
    height: 100%;
  }

  @include desktop {
    width: 50%;
  }
}

.list.open {
  display: block;
}

.list__item {
  display: flex;
  flex-direction: column;
  font-weight: 400;

  @include tablet {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    font-size: 1rem;
  }

  @include desktop {
    font-size: 1.2rem;

  }

  &--contact {
    background-color: #ff5733;
    color: white;
    padding: 8px 25px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
    width: 50%;

    @include tablet {
      font-size: 1rem;
      width: 100%;
    }

    @include desktop {
      font-size: 1rem;
    }
  }
}
